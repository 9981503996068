import styled from "styled-components";

const Trusted = () => {
  return (
    <Wrapper className="brand-section">
      <div className="container">
        <h3>Top Selling Store on Daraz.pk</h3>
        <div className="brand-section-slider">
          {/* my 1st img  */}
          <div>
            <a href="https://www.daraz.pk/shop/hamza-s-retro-consoles/?spm=a2a0e.pdp.seller.1.3beaiT1qiT1qQP&itemId=425182838&channelSource=pdp">
              <img src="images/darazlogo.png" alt="trusted-brands" />
              <p>Go to Daraz store</p> {/* Added CTA text */}
            </a>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 9rem 0;
  background-color: ${({ theme }) => theme.colors.bg};

  .brand-section {
    padding: 12rem 0 0 0;
  }
  h3 {
    text-align: center;
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.text};
    font-size: 2rem;
    font-weight: bold;
  }

  img {
    min-width: 12rem; /* Increased image size */
    height: 12rem; /* Increased image size */
    transition: transform 0.3s ease; /* Added transition for hover effect */
  }

  p {
    text-align: center;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.text};
    margin-top: 1rem; /* Added spacing between image and text */
    opacity: 0; /* Hide text by default */
    transition: opacity 0.3s ease; /* Added transition for fade-in effect */
  }

  /* Cursor-based hover effect */
  a:hover img {
    transform: scale(1.1); /* Increase image size on hover */
  }

  a:hover p {
    opacity: 1; /* Fade in text on hover */
  }

  /* Touch feedback */
  a:active img {
    transform: scale(0.95); /* Decrease image size on tap */
  }

  /* Show text on tap */
  a:active p {
    opacity: 1; /* Fade in text on tap */
  }

  .brand-section-slider {
    margin-top: 3.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .brand-section-slider {
      margin-top: 3.2rem;
      display: flex;
      justify-content: center; /* Center horizontally */
      align-items: center; /* Center vertically */
      flex-direction: column;
    }
  }
`;

export default Trusted;
