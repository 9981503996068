import React, { useState } from "react";
import styled from "styled-components";
import ProductLists from "./components/ProductLists";
import Sort from "./components/Sort";
import FilterSection from "./components/FilterSection";
import { useFilterContext } from "./context/FilterContext";
import { FaFilter } from "react-icons/fa";

const Products = () => {
  const filter_products = useFilterContext();
  const [filterOpen, setFilterOpen] = useState(true); // Set initial state to true

  const toggleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  return (
    <Wrapper>
      <div className="container grid grid-filter-column">
        <div>
          <FilterHeader onClick={toggleFilter}>
            <FaFilter color="#000" />
            <FilterText>Filters</FilterText>
          </FilterHeader>
          {filterOpen && <FilterSection />}
        </div>

        <section className="product-view--sort">
          <div className="sort-filter">
            <Sort />
          </div>
          <div className="main-product">
            <ProductLists />
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .grid-filter-column {
    grid-template-columns: 0.2fr 1fr;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .grid-filter-column {
      grid-template-columns: 1fr;
    }
  }
`;

const FilterHeader = styled.div`
  cursor: pointer;
  display: none;

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.black}; /* Changed color to black */

    svg {
      font-size: 1.5rem;
    }
  }
`;

const FilterText = styled.span`
  font-size: 1.8rem; /* Increased font size */
  font-weight: bold; /* Added bold font weight */
`;

export default Products;
