const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const ProductReducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: true,
      };

    case "API_ERROR":
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case "SET_SINGLE_LOADING":
      return {
        ...state,
        isSingleLoading: true,
      };

    case "SET_SINGLE_PRODUCT":
      return {
        ...state,
        isSingleLoading: false,
        singleProduct: action.payload,
      };

    case "SET_SINGLE_ERROR":
      return {
        ...state,
        isSingleLoading: false,
        isError: true,
      };

    case "SET_API_DATA":
      const shuffledProducts = shuffleArray(action.payload);
      const randomFeatureProducts = shuffledProducts.slice(0, 4);

      return {
        ...state,
        isLoading: false,
        isError: false,
        products: action.payload,
        featureProducts: randomFeatureProducts,
      };

    default:
      return {
        ...state,
      };
  }
};

export default ProductReducer;
