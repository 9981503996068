// src/components/WhatsAppButton.js
import React from "react";
import styled from "styled-components";
import { FaWhatsapp } from "react-icons/fa";

const whatsAppButton = () => {
  const phoneNumber = "+923025259628"; // Replace with your WhatsApp number
  const message = "Hello! I would like to inquire about your products."; // Customize your message

  const handleClick = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <Button onClick={handleClick}>
      <FaWhatsapp size={32} />
    </Button>
  );
};

const Button = styled.div`
  position: fixed;
  bottom: 15px;
  right: 45px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

export default whatsAppButton;
