import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Nav from "./Nav";

const Header = () => {
  return (
    <MainHeader>
      <NavLink to="/" className="logo-container">
        <Logo src="./images/logo.png" alt="website logo" />
        <SiteName>Hamza's Retro Consoles</SiteName>
      </NavLink>
      <Nav />
    </MainHeader>
  );
};

const MainHeader = styled.header`
  height: 10rem;
  background-color: ${({ theme }) => theme.colors.bg};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem; /* Added padding for better appearance */
`;

const Logo = styled.img`
  height: 7rem; /* Adjusted height */
  width: auto; /* Maintains aspect ratio */
`;

const SiteName = styled.span`
  font-size: 4rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text};
  margin-top: 20rem; /* Added margin to the bottom */
  margin-left: 1rem; /* Added margin for spacing */

  /* Google Font */
  font-family: "Orbitron"; /* Using Orbitron font */
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    font-size: 3rem; /* Adjust font size for mobile */
  }
`;

const GlobalStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap");
`;

export default Header;
