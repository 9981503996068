import React from "react";
import { NavLink } from "react-router-dom";
import FormatPrice from "../Helpers/FormatPrice";

const Product = (curElem) => {
  const { _id, title, description, color, images, quantity, price } = curElem;
  return (
    <NavLink to={`/singleproduct/${_id}`}>
      <div className="card">
        <figure>
          <img src={images[0]} alt={title} />
          <figcaption className="caption">
            <FormatPrice price={price} />
          </figcaption>
        </figure>
        <div className="card-data">
          <div className="card-data-flex">
            <h3>{title}</h3>
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default Product;
