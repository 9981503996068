import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { FaVolumeUp, FaVolumeMute } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const MusicPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/products") {
      audioRef.current.volume = 0.06; // Set volume to 10%
      audioRef.current.play();
      setIsPlaying(true);
    }
  }, [location]);

  const toggleMusic = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <MusicButton onClick={toggleMusic}>
      <audio id="backgroundMusic" loop ref={audioRef}>
        <source src="music/background.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      {isPlaying ? <FaVolumeUp /> : <FaVolumeMute />}
    </MusicButton>
  );
};

const MusicButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.black};
  z-index: 1000;

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    font-size: 2.5rem; /* Increased font size for mobile */
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export default MusicPlayer;
