import styled from "styled-components";

const Contact = () => {
  const Wrapper = styled.section`
    padding: 9rem 0 5rem 0;
    text-align: center;

    .container {
      margin-top: 6rem;

      .contact-form {
        max-width: 50rem;
        margin: auto;

        .contact-inputs {
          display: flex;
          flex-direction: column;
          gap: 3rem;

          input[type="submit"] {
            cursor: pointer;
            transition: all 0.2s;

            &:hover {
              background-color: ${({ theme }) => theme.colors.white};
              border: 1px solid ${({ theme }) => theme.colors.btn};
              color: ${({ theme }) => theme.colors.btn};
              transform: scale(0.9);
            }
          }
        }
      }
    }
  `;

  return (
    <Wrapper>
      <h2 className="common-heading">Contact Us</h2>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d850.884586415365!2d74.39049!3d31.454374!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1709388894252!5m2!1sen!2s"
        width="100%"
        height="400"
        style={{ border: 0 }}
        allowFullscreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      <div className="container">
        <div className="contact-form">
          <form
            action="https://formspree.io/f/xleqnvka"
            method="POST"
            className="contact-inputs"
          >
            <input
              type="text"
              placeholder="Name"
              name="name"
              required
              autoComplete="off"
            />
            {/* <input
              type="text"
              placeholder="Mobile no."
              name="mobileno"
              required
              autoComplete="off"
            /> */}
            <input
              type="tel"
              id="mobile_number"
              name="mobile_number"
              placeholder="Mobile no."
              pattern="03[0-9]{9}"
              required
            ></input>
            <textarea
              name="message"
              placeholder="Enter your message..."
              cols="30"
              rows="10"
              required
              autoComplete="off"
            />
            <input type="submit" value="send" />
          </form>
        </div>
      </div>
    </Wrapper>
  );
};

export default Contact;
