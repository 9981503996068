import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import HeroSection from "./components/HeroSection";
import Services from "./components/Services";
import Trusted from "./components/Trusted";

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const FadeInDiv = styled.div`
  opacity: 0;
  transform: translateY(20px);
  animation: ${fadeInAnimation} 0.5s ease-out forwards;
  animation-delay: ${(props) => props.delay};

  @media (max-width: 768px) {
    display: ${(props) => (props.hideOnMobile ? "none" : "block")};
  }
`;

const Home = () => {
  const [fadeIn, setFadeIn] = useState({
    hero: false,
    feature: false,
    services: false,
    trusted: false,
  });

  useEffect(() => {
    const fadeInTimer1 = setTimeout(() => {
      setFadeIn((prev) => ({ ...prev, hero: true }));
    }, 500);

    const fadeInTimer2 = setTimeout(() => {
      setFadeIn((prev) => ({ ...prev, feature: true }));
    }, 1000);

    const fadeInTimer3 = setTimeout(() => {
      setFadeIn((prev) => ({ ...prev, services: true }));
    }, 1500);

    const fadeInTimer4 = setTimeout(() => {
      setFadeIn((prev) => ({ ...prev, trusted: true }));
    }, 2000);

    // Clear timeouts on component unmount
    return () => {
      clearTimeout(fadeInTimer1);
      clearTimeout(fadeInTimer2);
      clearTimeout(fadeInTimer3);
      clearTimeout(fadeInTimer4);
    };
  }, []);

  return (
    <>
      <FadeInDiv delay="0.5s" style={{ animationDelay: "0.5s" }}>
        <HeroSection
          myData={{
            paragraph:
              "Welcome to Hamza's Retro Consoles. Your best place to buy Retro Consoles in Pakistan!",
          }}
        />
      </FadeInDiv>

      <FadeInDiv delay="1s" style={{ animationDelay: "1.5s" }}>
        <Trusted />
      </FadeInDiv>
      <FadeInDiv delay="1.5s" style={{ animationDelay: "2s" }}>
        <Services />
      </FadeInDiv>
    </>
  );
};

export default Home;
