import React from "react";
import HeroSection from "./components/HeroSection";

const About = () => {
  return (
    <HeroSection
      myData={{
        paragraph:
          "Welcome to Hamza's Retro Console, Pakistan's premier destination for retro gaming enthusiasts! Founded with a passion for nostalgia and a love for classic gaming, we are the first dedicated online store in Pakistan specializing in Nintendo DS, DSi, 3DS, DSi XL, and a curated selection of other retro gaming products.\n\nAt Hamza's Retro Console, we understand the thrill of reliving the golden era of gaming and the importance of preserving these timeless classics. Whether you're a seasoned gamer looking to revisit your favorite titles or a newcomer eager to explore the rich history of gaming, we've got you covered.\n\nOur commitment goes beyond just selling products; we strive to provide an authentic and memorable shopping experience. With a carefully curated inventory, knowledgeable staff, and a user-friendly online platform, we make it easy for you to find the perfect retro gaming treasure.\n\nLocated in Lahore, we are proud to serve the gaming community across Pakistan and beyond. Our mission is to make retro gaming accessible, affordable, and enjoyable for everyone.\n\nThank you for choosing Hamza's Retro Console as your trusted source for all things retro gaming. Join us on this nostalgic journey and let's celebrate the classics together!",
      }}
    />
  );
};

export default About;
