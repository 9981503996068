import styled from "styled-components";
import { TbTruckDelivery } from "react-icons/tb";
import { MdSecurity } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import { RiSecurePaymentLine } from "react-icons/ri";

const Services = () => {
  return (
    <Wrapper>
      <div className="container">
        <div className="grid">
          <div className="services-2">
            <div className="services-colum-2">
              <div>
                <RiSecurePaymentLine className="icon" />
                <h3>Trusted Store</h3>
              </div>
            </div>
            <div className="services-colum-2">
              <div>
                <TbTruckDelivery className="icon" />
                <h3>Cash On Delivery</h3>
              </div>
            </div>
            <div className="services-colum-2">
              <div>
                <MdSecurity className="icon" />
                <h3>Same Day Delivery In Lahore</h3>
              </div>
            </div>
            <div className="services-colum-2">
              <div>
                <GiReceiveMoney className="icon" />
                <h3>Guaranteed to Work .</h3>
              </div>
            </div>
          </div>
          {/*
           */}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 9rem 0;

  .grid {
    gap: 4.8rem;
  }

  .services-1,
  .services-2,
  .services-3 {
    width: auto;
    height: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background: ${({ theme }) => theme.colors.bg};
    text-align: center;
    border-radius: 2rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }

  .services-2 {
    gap: 4rem;
    background-color: transparent;
    box-shadow: none;

    .services-colum-2 {
      background: ${({ theme }) => theme.colors.bg};
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: center; /* Center alignment by default */
      align-items: center;
      border-radius: 2rem;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;

      div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start; /* Align to left */
        align-items: center;
        gap: 1rem;
      }
    }
  }

  h3 {
    margin-top: 1.4rem;
    font-size: 2rem;
    text-align: center; /* Center alignment by default */
  }

  .icon {
    width: 8rem;
    height: 8rem;
    padding: 2rem;
    border-radius: 50%;
    background-color: #fff;
    color: #5138ee;
  }

  @media screen and (max-width: 768px) {
    .services-2 .services-colum-2 {
      justify-content: left;
    }
  }
`;

export default Services;
